<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 uppercase text-2xl font-semibold py-2">
        Rapports des achats par article
      </div>
    </PageHeader>
    <div class="p-6">
      <div class="flex justify-end items-center">

        <el-select
            v-model="typeSearch"
            class="mr-2"
            placeholder="Select"
            @change="HANDLE_PLAGE"
        >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
            v-model="listQuery.start"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd MMM yyyy"
            placeholder="Date debut"
        >
        </el-date-picker>
        <span class="mx-2">Au</span>
        <el-date-picker
            class="mr-2"
            v-model="listQuery.end"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd MMM yyyy"
            placeholder="Date debut"
        ></el-date-picker>

        <el-button
            @click="INIT_DATA"
            class="ml-2"
            icon="el-icon-search"
            :disabled="btnSeachValid"
        >
          Rechercher
        </el-button>
        <el-button class="ml-2" @click="exportRapportBillArticleExcel" :loading="exportLoading">
          <i class="el-icon-download"></i> Exporter en excel

        </el-button>
<!--        <el-dropdown @command="HANDLE_EXPORT">
          <el-button class="ml-2" :loading="exportLoading">
            Exporter
            <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="PDF">En PDF </el-dropdown-item>
            <el-dropdown-item command="EXCEL">En EXCEL</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>-->
      </div>
      <div v-loading="listLoading">

        <div class="p-4 my-5 bg-white shadow">

          <div class="customer-white">
            <div class="flex justify-end items-center mb-5">
              <div class="mr-10 text-center">
                <div class="text-xl font-semibold">
                  {{ listQuery.total }}
                </div>
                <div class="text-gray-400 my-2">
                  <span>Unités achatées</span>

                  <!--    <span v-if="listQuery.type == 'CUSTOMER'">Nombre de facture</span> -->
                </div>
              </div>


              <div class="mr-10 text-center">
                <div class="text-xl font-semibold">
                  {{ this.listQuery.total_purchase | moneyFilter }} {{user.currency_code}}
                </div>
                <div class="text-gray-400 my-2">Coût d'achat des articles</div>
              </div>

            </div>
          </div>

          <el-table :data="items" v-loading="listLoading" style="width: 100%">
            <el-table-column label="CODE ARTICLE" width="200">
              <template slot-scope="{ row }">
                <span class="text-blue">{{ row.reference }}</span>
              </template>
            </el-table-column>

            <el-table-column
                prop="purchaseorder_date"
                label="CATÉGORIE"
                width="200"
            >
              <template slot-scope="{ row }">
                <span>{{ row.category }}</span>
              </template>
            </el-table-column>

            <el-table-column
                prop="purchaseorder_date"
                label="DÉSIGNATION"
                min-width="200"
            >
              <template slot-scope="{ row }">
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Quantité" align="center" width="110">
              <template slot-scope="{ row }">
                <span class="">{{ row.quantity }} </span>
              </template>
            </el-table-column>

            <el-table-column label="Coût d'achat" width="160" align="right">
              <template slot-scope="{ row }">
                <span class="">{{ row.amount | moneyFilter }} </span>
              </template>
            </el-table-column>

          </el-table>
          <div class="flex justify-end">
            <pagination
                v-show="listQuery.total > 0"
                :total="listQuery.total"
                :page.sync="listQuery.page"
                :limit.sync="listQuery.size"
                @pagination="INIT_DATA()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@components/Pagination/index.vue";
import { toThousandFilter } from "@/Filters";
//import ChartSale from "./chart.vue";

import {
  parseDate,
  getDay,
  getStartOfWeek,
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth,
  getStartOfYear,
  getEndOfYear,
} from "@/utils";
import {
  fetchRapportBillArticle,
} from "@/api/purchase";

import {  exportExcelRapportBillByArticle } from "@/api/export";

import PageHeader from "@components/PageHeader/index.vue";
import {mapGetters} from "vuex";

export default {
  name: "Rapport-Sales",

  components: {Pagination, PageHeader },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },

  data() {
    return {
      typeSearch: "week",
      exportLoading: false,
      showChart: false,
      activeName: "ALL",
      employees: [],
      customers: [],

      options: [
        {
          label: "Journalier",
          value: "day",
        },
        {
          label: "Hebdomadaire",
          value: "week",
        },
        {
          label: "Mensuel",
          value: "month",
        },
        {
          label: "Annuel",
          value: "year",
        },
      ],
      chartCategories: [],
      chartSeries: [],

      resume: {
        total_invoice: 0,
        cost_purchase: 0,
        count_item: 0,
        profit: 0,
      },
      listLoading: false,
      items: [],
      typeData: [
        { label: "Par clients", value: "CUSTOMER" },
        { label: "Par articles", value: "ARTICLE" },
      ],
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "name",
        start: getStartOfWeek(),
        end: getEndOfWeek(),
        type: undefined,
        contactId: undefined,
        total_purchase:0
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    btnSeachValid() {
      return !(this.listQuery.start !== null && this.listQuery.end !== null);

    },
  },
  mounted() {
    this.INIT_DATA();
  },
  methods: {

    async GET_FETCH_RAPPORT() {
      this.listLoading = true;

      await fetchRapportBillArticle(this.listQuery).then((res) => {
        setTimeout(() => {

          const data = res.data.items;
          this.items = data;
          this.listQuery.total_purchase = res.data.total_purchase;

          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;

          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },



    INIT_DATA() {
      if (this.listQuery.start !== null && this.listQuery.end !== null) {
        if (
            this.listQuery.saleagent === "" ||
            this.listQuery.saleagent === null
        ) {
          this.listQuery.saleagent = undefined;
        }
        this.GET_FETCH_RAPPORT();
      }
    },

    HANDLE_PLAGE(e) {
      if (e === "day") {
        this.listQuery.start = getDay();
        this.listQuery.end = getDay();
      }
      if (e === "week") {
        this.listQuery.start = getStartOfWeek();
        this.listQuery.end = getEndOfWeek();
      }
      if (e === "month") {
        this.listQuery.start = getStartOfMonth();
        this.listQuery.end = getEndOfMonth();
      }
      if (e === "year") {
        this.listQuery.start = getStartOfYear();
        this.listQuery.end = getEndOfYear();
      }
      this.INIT_DATA();
    },

  /*  exportRapportSalesPDF() {
      this.exportLoading = true;
      if (this.listQuery.saleagent == "" || this.listQuery.saleagent === null) {
        this.listQuery.saleagent = undefined;
      }
      exportRapportSale(this.listQuery)
          .then((res) => {
            setTimeout(() => {
              const url = window.URL.createObjectURL(new Blob([res]));
              const link = document.createElement("a");

              link.href = url;
              link.setAttribute(
                  "download",
                  "rapporte_vente_du" +
                  this.listQuery.start +
                  "_" +
                  this.listQuery.end +
                  ".pdf"
              ); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.exportLoading = false;
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
    },*/
    exportRapportBillArticleExcel() {
      this.exportLoading = true;
      exportExcelRapportBillByArticle(this.listQuery)
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                this.listQuery.end + "_" + this.listQuery.end + "rapport_achat_articles.xls"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.exportLoading = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    HANDLE_TYPE_RAPPORT() {
      this.INIT_DATA();
    },
    HANDLE_EXPORT(command) {
      switch (command) {
        case "PDF":
          this.exportRapportSalesPDF();
          break;
        case "EXCEL":
          this.exportRapportSalesExcel();
          break;
      }
    },
    handleShowChart() {
      this.showChart = !this.showChart;
    },
    handleTab(obj) {
      const name = obj.name;

      switch (name) {
        case "INVOICE":
          this.listQuery.type = "INVOICE";
          break;
        case "TICKET_POS":
          this.listQuery.type = "TICKET_POS";
          break;

        default:
          this.listQuery.type = undefined;
      }
      this.INIT_DATA();
    },
    handleCustomer(id) {
      this.listQuery.contactId = id;

    },
  },
};
</script>

<style lang="scss" scoped></style>
